
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import NoData from '@/components/layout/NoData.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        Breadcrumb, NoData,
        Edit, Delete, Attention, CheckOne
    },
    data(){
        return {
            list: [],
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        this.getRolesList();
    },
    methods: {
        getRolesList(){
            this.$api.getRoles().then((res: any) => {
                this.list = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.destroyRole(id).then((res: any) => {
                if(res.msg === 'success') {
                    this.list.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '权限删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    },
})

export default class Roles extends Vue {}
